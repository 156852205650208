<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog">
    <v-card>
      <v-card-title primary-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close"
          ><v-icon>{{ icons.close }}</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-form :ref="formRef">
          <v-autocomplete
            v-model="form.ramo"
            :items="ramos"
            item-text="ramo_descripcion"
            item-value="_id"
            item-disabled="eliminado"
            label="Ramo"
            :loading="loadingRamos"
            :rules="[(v) => !!v || 'Debe seleccionar un ramo.']"
          ></v-autocomplete>

          <v-text-field
            v-model="form.segmento_descripcion"
            name="tituloSegmento"
            label="Segmento"
            placeholder="Escriba el Título del segmento..."
            :rules="[(v) => !!v || 'El campo segmento es requerido.']"
          >
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit">{{ labels.submit }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";
import { mapActions } from "vuex";

export default {
  name: "SegmentoModalForm",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario de Segmento",
      form: {
        segmento_descripcion: "",
        ramo: "",
      },
      ramos: [],
      loadingRamos: false,
    };
  },
  methods: {
    ...mapActions("NewProduct", ["getRamos"]),

    async setup() {
      this.loadingRamos = true;
      try {
        const response = await this.getRamos();
        this.ramos = response;
      } catch (error) {
        this.snackbar(error.result?.message || error.info);
      }
      this.loadingRamos = false;
    },
  },
};
</script>
